import { Component, OnInit } from '@angular/core';
import { HttpUtilsService } from './services/http-utils.service';
import { User } from 'src/app/models/user.model';
import { LoginService } from './services/login.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'corporate-discount-server';
  isLoggedIn = false;
  user!: User;
  user_role!: string;
  isOtp = false;

  constructor(private utils: HttpUtilsService, private service: LoginService, private _snackBar: MatSnackBar) {}

  ngOnInit(): void {
      const token = localStorage.getItem('cp_token');
      if (token) {
        this.service.checkToken(token).subscribe(
          data => {
            // token is still valid
            this.utils.token = token;
            this.isLoggedIn = true;
            this.user_role = data.user_role;
          },
          err => {
            // invalid token
            console.log('error');
            localStorage.removeItem('cp_token');
          }
        )
      }
  }

  openSnackBar(message: string, action: string, duration: number = 2500) {
    this._snackBar.open(message, action, {
      duration: duration
    });
  }

  handleError(err: HttpErrorResponse) {
    if (err.error['error']) {
      this.openSnackBar(err.status + ": " + err.error['error'], 'Dismiss');
    } else {
      this.openSnackBar(err.status + ": Unknown server error", 'Dismiss');
    }
  }

  login(credentials: {username: string, password: string}): void {
    this.service.authenticate(credentials.username, credentials.password).subscribe(
      data => {
        console.log('logged in', data);
        this.user = data;
        this.isOtp = true;
      },
      err => {
        console.error("Error logging in");
        this.handleError(err);
      }
    );
  }

  submitOtp(otp: string): void {
    console.log("otp", otp);
    this.service.confirmOtp(this.user, otp).subscribe(
      data => {
        this.user_role = this.user.role;
        this.utils.token = data.token;
        localStorage.setItem('cp_token', data.token);
        this.isLoggedIn = true;
        this.isOtp = false;
      },
      err => {
        this.isOtp = false;
        if (err.error['error'] == 'OTP expired') {
          console.error("Error OTP expired");
        } else {
          console.error("Error OTP");
        }
        this.handleError(err);
      }
    );
    
  }

  logout(): void {
    this.user_role = "";
    this.utils.token = "";
    this.isLoggedIn = false;
    this.isOtp = false;
    localStorage.removeItem('cp_token');
  }
}

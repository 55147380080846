<div class="form-page-container">

    <button mat-mini-fab class="back-button" (click)="goBack.emit()">
    <mat-icon>arrow_back</mat-icon>
  </button>

    <form class="form-container" [formGroup]="vendorForm" (ngSubmit)="submitForm()">

        <div class="form-title">{{isEdit ? 'Update' : 'Add'}} Vendor</div>

        <mat-label class="field-label">Name</mat-label>
        <mat-form-field appearance="outline">
            <input matInput formControlName="name">
        </mat-form-field>

        <mat-label class="field-label">Name Arabic</mat-label>
        <mat-form-field appearance="outline">
            <input matInput formControlName="name_ar">
        </mat-form-field>

        <mat-label class="field-label">Description</mat-label>
        <mat-form-field appearance="outline">
            <input matInput formControlName="description">
        </mat-form-field>

        <mat-label class="field-label">Description Arabic</mat-label>
        <mat-form-field appearance="outline">
            <input matInput formControlName="description_ar">
        </mat-form-field>

        <mat-label class="field-label">Logo</mat-label>
        <div class="image-container">
            <img *ngIf="logoUrl" class="img" [src]="'/api' + logoUrl">
            <mat-icon class="delete-icon" *ngIf="logoUrl" (click)="removeImage()">highlight_off</mat-icon>
        </div>
        <app-upload-image #uploadImage></app-upload-image><br>

        <div formArraryName="mobiles">
            <mat-label class="field-label">Mobile</mat-label>
            <ng-container *ngFor="let mob of mobiles.controls; index as i">
                <mat-form-field appearance="outline">
                    <input matInput [formControl]="$any(mobiles.controls[i])">
                    <mat-icon matSuffix class="remove-icon" *ngIf="mobiles.controls.length > 1" (click)="removeMobileAt(i)">delete_forever
                    </mat-icon><br>
                </mat-form-field>
            </ng-container>
        </div>
        <a class="clickable-text under-form-field" (click)="addMobile()">+ Add another mobile</a><br>

        <div formArraryName="telephones">
            <mat-label class="field-label">Telephone</mat-label>
            <ng-container *ngFor="let tele of telephones.controls; index as i">
                <mat-form-field appearance="outline">
                    <input matInput [formControl]="$any(telephones.controls[i])">
                    <mat-icon matSuffix class="remove-icon" *ngIf="telephones.controls.length > 1" (click)="removeTelephoneAt(i)">
                        delete_forever</mat-icon><br>
                </mat-form-field>
            </ng-container>
        </div>
        <a class="clickable-text under-form-field" (click)="addTelephone()">+ Add another telephone</a><br>

        <div formArraryName="emails">
            <mat-label class="field-label">E-mail</mat-label>
            <ng-container *ngFor="let em of emails.controls; index as i">
                <mat-form-field appearance="outline">
                    <input matInput [formControl]="$any(emails.controls[i])">
                    <mat-icon matSuffix class="remove-icon" *ngIf="emails.controls.length > 1" (click)="removeEmailAt(i)">delete_forever
                    </mat-icon><br>
                </mat-form-field>
            </ng-container>
        </div>
        <a class="clickable-text under-form-field" (click)="addEmail()">+ Add another e-mail</a><br>

        <mat-label class="field-label">Website</mat-label>
        <mat-form-field appearance="outline">
            <input matInput formControlName="website">
        </mat-form-field>

        <mat-label class="field-label">SME Name</mat-label>
        <mat-form-field appearance="outline">
            <input matInput formControlName="smeName">
        </mat-form-field>

        <mat-label class="field-label">SME E-mail</mat-label>
        <mat-form-field appearance="outline">
            <input matInput formControlName="smeEmail">
        </mat-form-field>

        <mat-label class="field-label">SME Phone</mat-label>
        <mat-form-field appearance="outline">
            <input matInput formControlName="smePhone">
        </mat-form-field>

        <mat-label class="field-label">Locations</mat-label>
        <mat-form-field appearance="outline">
            <mat-chip-list #loctaionsChips>
                <mat-chip *ngFor="let location of addedLocations" (removed)="removeLocation(location)">
                    {{location.country}}, {{location.city}}
                    <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
                </mat-chip>
                <input type="text" #locationInput matInput formControlName="locations" [matAutocomplete]="autoLocation" [matChipInputFor]="loctaionsChips">
            </mat-chip-list>
            <mat-autocomplete #autoLocation="matAutocomplete" (optionSelected)="selectedLocation($event)">
                <mat-option *ngFor="let location of filteredLocations | async" [value]="location">
                    {{location.country}}, {{location.city}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <button mat-raised-button class="big-button" type="submit" [disabled]="!vendorForm.valid">
      {{isEdit ? 'Update' : 'Add'}} Vendor
    </button>

    </form>
</div>
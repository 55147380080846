import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpUtilsService } from './http-utils.service';
import { User } from 'src/app/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  
  constructor(private http: HttpClient, private utils: HttpUtilsService) { }

  authenticate(username: string, password: string): Observable<User> {
    return this.http.post<User>('/api/cmsLogin', {username: username, password: password}, this.utils.httpOptions); // fetching data from api
  }

  confirmOtp(user: User, otp: string): Observable<any> {
    return this.http.post<any>('/api/confirm-otp', {user: user, otp: otp}, this.utils.httpOptions); // fetching data from api
  }

  checkToken(token: string): Observable<{user_role: string}> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization' : token,
      'responseType': 'text',
    });
    return this.http.get<{user_role: string}>('/api/get-role', {headers: headers}); // fetching data from api
  }
}

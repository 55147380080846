<div class="form-page-container">

    <button mat-mini-fab class="back-button" (click)="goBack.emit()">
    <mat-icon>arrow_back</mat-icon>
  </button>

    <form [formGroup]="offerForm" (ngSubmit)="submitForm()" class="form-container">

        <div class="form-title">{{isEdit ? 'Update' : 'Add'}} Offer </div>

        <mat-label class="field-label">Category*</mat-label>
        <mat-form-field appearance="outline">
            <mat-select formControlName="category" [compareWith]="compareObjects" required>
                <mat-option *ngFor="let category of categories" [value]="category" (click)=showRelatedFields(category)>
                    {{category.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="under-form-field" *ngIf="showHotelSection">
            <mat-checkbox formControlName="isPartnerHotel">
                Partner Hotel
            </mat-checkbox><br><br>
        </div>

        <mat-label class="field-label">Vendor*</mat-label>
        <mat-form-field appearance="outline">
            <input type="text" matInput formControlName="vendor" [matAutocomplete]="autoVendor" required>
            <mat-autocomplete #autoVendor="matAutocomplete" [displayWith]="displayVendor" (optionSelected)="selectedVendor($event)">
                <mat-option *ngFor="let vendor of filteredVendors | async" [value]="vendor">
                    {{vendor.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <div class="clickable-text under-form-field" (click)="createVendorDialog()">
            Create New Vendor
        </div><br>

        <mat-label class="field-label">Offer Type</mat-label>
        <mat-radio-group formControlName="isOccasional">
            <mat-radio-button [value]="false">Regular Offer</mat-radio-button>
            <mat-radio-button [value]="true">Occasional Offer</mat-radio-button>
        </mat-radio-group><br>

        <mat-label class="field-label">Offer Title*</mat-label>
        <mat-form-field appearance="outline">
            <input matInput formControlName="title" required>
        </mat-form-field>

        <mat-label class="field-label">Arabic Title*</mat-label>
        <mat-form-field appearance="outline">
            <input matInput formControlName="title_ar" required>
        </mat-form-field>

        <mat-label class="field-label">Discount*</mat-label>
        <mat-form-field appearance="outline">
            <input matInput formControlName="description" required>
        </mat-form-field>

        <mat-label class="field-label">Arabic Discount*</mat-label>
        <mat-form-field appearance="outline">
            <input matInput formControlName="description_ar" required>
        </mat-form-field>

        <mat-label class="under-form-field small-text">
            Enter % of discount or price it start from. <br> Example: "20% Off Discount" or "Start From 240 SAR".
        </mat-label><br>

        <mat-label class="field-label">Discount Coupon</mat-label>
        <mat-form-field appearance="outline">
            <input matInput formControlName="discountCode">
        </mat-form-field>

        <mat-label class="field-label">Expiry Date</mat-label>
        <mat-form-field appearance="outline">
            <input matInput [matDatepicker]="picker" formControlName="expiryDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-label class="field-label">Redeem Instructions</mat-label>
        <mat-form-field appearance="outline">
            <textarea matInput formControlName="howToAvail"></textarea>
        </mat-form-field>

        <mat-label class="field-label">Arabic Redeem Instructions</mat-label>
        <mat-form-field appearance="outline">
            <textarea matInput formControlName="howToAvail_ar"></textarea>
        </mat-form-field>

        <mat-label class="field-label">Offer Image</mat-label>
        <div class="image-container">
            <img *ngIf="imageUrl" class="img" [src]="'/api' + imageUrl">
            <mat-icon class="delete-icon" *ngIf="imageUrl" (click)="removeImage()">highlight_off</mat-icon>
        </div>
        <app-upload-image #uploadImage></app-upload-image><br>

        <mat-label class="field-label">Featured Offer</mat-label>
        <mat-radio-group formControlName="isFeatured">
            <mat-radio-button [value]="true">Yes</mat-radio-button>
            <mat-radio-button [value]="false">No</mat-radio-button>
        </mat-radio-group><br>

        <ng-container *ngIf="this.offerForm.get('isFeatured')?.value">
            <mat-label class="field-label">Featured Image</mat-label>
            <div class="image-container">
                <img *ngIf="featuredImageUrl" class="img" [src]="'/api' + featuredImageUrl">
                <mat-icon class="delete-icon" *ngIf="featuredImageUrl" (click)="removeFeaturedImage()">highlight_off</mat-icon>
            </div>
            <app-upload-image #uploadFeaturedImage></app-upload-image><br>
        </ng-container>

        <mat-label class="field-label">Offer Highlights</mat-label>
        <mat-form-field appearance="outline">
            <textarea matInput formControlName="highlights"></textarea>
        </mat-form-field>

        <mat-label class="field-label">Arabic Highlights</mat-label>
        <mat-form-field appearance="outline">
            <textarea matInput formControlName="highlights_ar"></textarea>
        </mat-form-field>

        <mat-label class="field-label">Locations</mat-label>
        <mat-form-field appearance="outline">
            <mat-chip-list #loctaionsChips>
                <mat-chip *ngFor="let location of addedLocations" (removed)="removeLocation(location)">
                    {{location.country}}, {{location.city}}
                    <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
                </mat-chip>
                <input type="text" #locationInput matInput formControlName="locations" [matAutocomplete]="autoLocation" [matChipInputFor]="loctaionsChips">
            </mat-chip-list>
            <mat-autocomplete #autoLocation="matAutocomplete" (optionSelected)="selectedLocation($event)">
                <mat-option *ngFor="let location of filteredLocations | async" [value]="location">
                    {{location.country}}, {{location.city}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-label class="field-label">Search Map</mat-label>
        <mat-radio-group formControlName="enableMapSearch">
            <mat-radio-button [value]="true">Yes</mat-radio-button>
            <mat-radio-button [value]="false">No</mat-radio-button>
        </mat-radio-group><br>


        <div formArraryName="contacts">
            <mat-label class="field-label">Contact</mat-label>
            <ng-container *ngFor="let contact of contacts.controls; index as i">
                <mat-form-field appearance="outline">
                    <input matInput [formControl]="$any(contacts.controls[i])">
                    <mat-icon matSuffix class="remove-icon" *ngIf="contacts.controls.length > 1" (click)="removeContactAt(i)">delete_forever</mat-icon><br>
                </mat-form-field>
            </ng-container>
        </div>
        <a class="clickable-text under-form-field" (click)="addContact()">+ Add another contact</a><br>

        <div formArraryName="mobiles">
            <mat-label class="field-label">Mobile</mat-label>
            <ng-container *ngFor="let mob of mobiles.controls; index as i">
                <mat-form-field appearance="outline">
                    <input matInput [formControl]="$any(mobiles.controls[i])">
                    <mat-icon matSuffix class="remove-icon" *ngIf="mobiles.controls.length > 1" (click)="removeMobileAt(i)">delete_forever</mat-icon><br>
                </mat-form-field>
            </ng-container>
        </div>
        <a class="clickable-text under-form-field" (click)="addMobile()">+ Add another mobile</a><br>

        <div formArraryName="telephones">
            <mat-label class="field-label">Telephone</mat-label>
            <ng-container *ngFor="let tele of telephones.controls; index as i">
                <mat-form-field appearance="outline">
                    <input matInput [formControl]="$any(telephones.controls[i])">
                    <mat-icon matSuffix class="remove-icon" *ngIf="telephones.controls.length > 1" (click)="removeTelephoneAt(i)">delete_forever</mat-icon><br>
                </mat-form-field>
            </ng-container>
        </div>
        <a class="clickable-text under-form-field" (click)="addTelephone()">+ Add another telephone</a><br>

        <div formArraryName="emails">
            <mat-label class="field-label">E-mail</mat-label>
            <ng-container *ngFor="let em of emails.controls; index as i">
                <mat-form-field appearance="outline">
                    <input matInput [formControl]="$any(emails.controls[i])">
                    <mat-icon matSuffix class="remove-icon" *ngIf="emails.controls.length > 1" (click)="removeEmailAt(i)">delete</mat-icon><br>
                </mat-form-field>
            </ng-container>
        </div>
        <a class="clickable-text under-form-field" (click)="addEmail()">+ Add another e-mail</a><br>

        <span *ngIf="showHotelSection && !this.offerForm.get('isPartnerHotel')?.value">

      <hr><br>
      <mat-label class='field-label bold'>Hotel-Related Information</mat-label><br><br>

      <mat-label class="field-label">Tax Value</mat-label>
      <mat-form-field appearance="outline">
        <input matInput formControlName="taxValue">
      </mat-form-field><br>

      <mat-label class="field-label">Arabic Tax Value</mat-label>
      <mat-form-field appearance="outline">
        <input matInput formControlName="taxValue_ar">
      </mat-form-field><br>

      <mat-label class="field-label">Currency</mat-label>
      <mat-form-field appearance="outline">
        <mat-select formControlName="currency">
          <mat-option  *ngFor="let currency of currenciesList " value={{currency}}>
            {{currency}}
          </mat-option>
        </mat-select>
      </mat-form-field><br>

      <mat-label class="field-label">Hotel Amenitites</mat-label>
      <mat-form-field appearance="outline">
        <mat-select multiple formControlName="hotelAmenitites" [compareWith]="compareObjects">
          <mat-option *ngFor="let hotelAmenity of hotelAmenititesList" [value]="hotelAmenity">
            {{hotelAmenity.name}}
          </mat-option>
        </mat-select>
      </mat-form-field><br>

      <mat-label class="field-label bold">Hotel Room Details</mat-label><br><br>

      <div formArraryName="rooms">
        <ng-container *ngFor="let room of getRooms().controls; index as i" >
          <div  class="roomSection">
            <mat-icon matSuffix class="remove-room-icon" *ngIf="getRooms().controls.length > 1" (click)="removeRoomAt(i)">delete_forever</mat-icon>
          <mat-form-field appearance="outline">
            <mat-label class="field-label" >Room Name</mat-label>
            <input matInput [formControl]="$any(getRooms().controls[i].get('name'))">
          </mat-form-field> 
          <mat-form-field appearance="outline">
            <mat-label class="field-label" >Arabic Room Name</mat-label>
            <input matInput [formControl]="$any(getRooms().controls[i].get('name_ar'))">
          </mat-form-field> 


          <div formArraryName="roomRates" class="roomRates">
            <ng-container *ngFor="let rate of getRates(i).controls; index as j"><br>
              <div class="rateSection">
              <mat-form-field appearance="outline" class="fieldStyle">
                <mat-label class="field-label">Rate Season</mat-label>
                <input matInput [formControl]="$any(getRates(i).controls[j].get('season'))">
              </mat-form-field>

              <mat-form-field appearance="outline" class="fieldStyle">
                <mat-label class="field-label">Arabic Rate Season</mat-label>
                <input matInput [formControl]="$any(getRates(i).controls[j].get('season_ar'))">
              </mat-form-field>

           </div>
           <div class="rateSection">
            <mat-form-field appearance="outline" class="fieldStyle">
              <mat-label class="field-label">Rate Value</mat-label>
              <input matInput [formControl]="$any(getRates(i).controls[j].get('value'))">
            </mat-form-field>
            <mat-icon matSuffix class="remove-rate-icon" *ngIf="getRates(i).controls.length > 1" (click)="removeRateAt(i,j)">delete_forever</mat-icon>
        
           </div>
            </ng-container>
            <a class="clickable-text" (click)="addRoomRate(i)"> + Add Another Room Rate</a><br><br>
          </div>
          <mat-form-field appearance="outline">
            <mat-label class="field-label">Room Addtional Information</mat-label>
            <input matInput [formControl]="$any(getRooms().controls[i].get('additionalInformation'))">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label class="field-label">Arabic Room Addtional Information</mat-label>
            <input matInput [formControl]="$any(getRooms().controls[i].get('additionalInformation_ar'))">
          </mat-form-field>
        </div>
        </ng-container>
        <a class="clickable-text" (click)="addRoomControl()"> + Add Another Room</a><br><br>
      </div>
    </span>
        <button mat-raised-button class="big-button" type="submit" [disabled]="!offerForm.valid">
      {{isEdit ? 'Update' : 'Add'}} Offer
    </button>

    </form>
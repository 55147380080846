<div class="form-page-container">

    <button mat-mini-fab class="back-button" (click)="goBack.emit()">
    <mat-icon>arrow_back</mat-icon>
  </button>

    <form [formGroup]="locationForm" (ngSubmit)="submitForm()" class="form-container">

        <div class="form-title">{{isEdit ? 'Update' : 'Add'}} Location</div>

        <mat-label class="field-label">City</mat-label>
        <mat-form-field appearance="outline">
            <input matInput type="text" formControlName="city" />
        </mat-form-field>

        <mat-label class="field-label">City Arabic</mat-label>
        <mat-form-field appearance="outline">
            <input matInput type="text" formControlName="city_ar" />
        </mat-form-field>

        <mat-label class="field-label">Country</mat-label>
        <mat-form-field appearance="outline">
            <input matInput type="text" formControlName="country" />
        </mat-form-field>


        <mat-label class="field-label">Country Arabic</mat-label>
        <mat-form-field appearance="outline">
            <input matInput type="text" formControlName="country_ar" />
        </mat-form-field>


        <mat-label class="field-label">Longitude</mat-label>
        <mat-form-field appearance="outline">
            <input matInput type="text" formControlName="longitude" />
        </mat-form-field>

        <mat-label class="field-label">Latitude</mat-label>
        <mat-form-field appearance="outline">
            <input matInput type="text" formControlName="latitude" />
        </mat-form-field>

        <button mat-raised-button type="submit" class="big-button" [disabled]="!locationForm.valid">{{isEdit ? 'Update' :
      'Add'}} Location</button>
    </form>
</div>